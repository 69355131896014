import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./user.css";
import VisaContext from "../../../context/visa-context";
import MyLoader from "../../Loader/MyLoader";
import Dropdown from "react-bootstrap/Dropdown";

const UserPayment = () => {
  const ctxApi = useContext(VisaContext);
  const location = useLocation();
  const travelerId = location.state?.travelerId || null;
  const applications = ctxApi.applicatons;
  const fetchApplications = ctxApi.fetchApplications;

  // Filter applications by email
  const userApplications = Array.isArray(applications)
    ? applications.filter(
        (application) => application.postData.travelerId === travelerId
      )
    : [];

  // update api for status 💯 👍

  const handleStatusUpdate = async (travelerId) => {
    try {
      const response = await fetch("https://backend-visa2.vercel.app/adminApi/updateUserStatus", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ travelerId: travelerId, status: "Completed" }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
  
      // Optionally, refresh the list of travelers or update the UI to reflect the status change
      fetchApplications();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <>
      <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Final Informations
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Name</th>
                <th>Destination</th>
                <th>Price</th>
                <th>AddOns</th>
                <th>Selected Date</th>
                <th>Response Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {userApplications.map((datas, ind) => (
                <tr key={ind}>
                  <td>{datas.postData.name}</td>
                  <td>{datas.postData.lname}</td>
                  <td>{datas.postData.countryName}</td>
                  <td>{datas.postData.countryPrice}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        AddOns
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Flight{" "}
                          <span style={{ color: "red" }}>
                            {datas.postData.addOnPrices.flight}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Hotel 
                          <span style={{ color: "red" }}>
                             { datas.postData.addOnPrices.hotel}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Itinerary{" "}
                          <span style={{ color: "red" }}>
                            {datas.postData.addOnPrices.itinerary}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          CoverLetter{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            {datas.postData.addOnPrices.coverLetter}
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>{datas.postData.selectedDate}</td>
                  <td>{datas.postData.futureDate}</td>
                  <td>{datas.postData.Time}</td>
                  <td className="status_tableData">
                    <p>{datas.postData.status}</p>
                    {datas.postData.status === "Processing" && (
                      <button
                      className="status_button"
                        onClick={() => handleStatusUpdate(datas.postData.travelerId)}
                      >
                        Complete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default UserPayment;

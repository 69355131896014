import React from 'react'
import 
{BsCart3, BsGrid1X2Fill, BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, 
  BsListCheck, BsMenuButtonWideFill, BsFillGearFill}
 from 'react-icons/bs'
import { Link } from 'react-router-dom'

function Sidebar({openSidebarToggle, OpenSidebar}) {
  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
        <div className='sidebar-title'>
            <div className='sidebar-brand'>
            <img src="/Images/favicon.png" alt="" style={{width:"30px", height:"30px"}} className='icon_header'/>
                 Vijatour
            </div>
            <span className='icon close_icon' onClick={OpenSidebar}>X</span>
        </div>

        <ul className='sidebar-list'>
            <li className='sidebar-list-item' onClick={OpenSidebar}>
            <Link to={'/'}>
            <BsGrid1X2Fill className='icon'/> Dashboard
            </Link>              
            </li>
            <li className='sidebar-list-item' onClick={OpenSidebar}>
            <Link to={'/user'}>
            <BsPeopleFill className='icon'/> Customers
            </Link>
            </li>
            <li className='sidebar-list-item' onClick={OpenSidebar}>
                <Link to={'/applications'}>
                    <BsFillGrid3X3GapFill className='icon'/>Applications
              </Link>
            </li>
            
            <li className='sidebar-list-item' onClick={OpenSidebar}>
                <Link >
                    <BsMenuButtonWideFill className='icon'/> Reports
                </Link>
            </li>
            <li className='sidebar-list-item' onClick={OpenSidebar}>
                <Link>
                    <BsFillGearFill className='icon'/> Setting
                </Link>
            </li>
        </ul>
    </aside>
  )
}

export default Sidebar
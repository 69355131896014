import React, {useContext} from 'react'
import VisaContext from "../../../context/visa-context.js";
import MyLoader from "../../Loader/MyLoader.jsx";

const TmsDatas = () => {
    const ctxApi = useContext(VisaContext); 

  return (
    <>
      <main className="main-container">
      {ctxApi.isLoading && <MyLoader />}
      <div className="main-title">
        <h3 style={{ color: "#13e313", fontSize: "25px" }}>The Migration School</h3>
      </div>      
      <div className="userpage">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile No.</th>
            </tr>
          </thead>
          <tbody>
            {ctxApi.tmsData.map((user) => (
              <tr key={user._id}>               
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.mobile}</td>              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
    </>
  )
}

export default TmsDatas;

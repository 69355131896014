import React, { useContext } from 'react'
import VisaContext from '../../../../context/visa-context'
import MyLoader from '../../../Loader/MyLoader'

const AllWorkVisaApplications = () => {
    const ctxData = useContext(VisaContext)

    console.log("workApplications", ctxData.workApplications)
  return (
    <>
          <main className="main-container">
        {ctxData.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Work Visa Applications
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Name</th>
                <th>Destination</th>
                <th>Price</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
            {ctxData.workApplications.map((item,ind)=>(
                <tr key={ind}>
                <td>{item && item.workVisa.name}</td>
                <td>{item &&  item.workVisa.lname}</td>
                <td>{item &&  item.workVisa.countryName}</td>
                <td>{item &&  item.workVisa.countryPrice}</td>
                <td>{item &&  item.workVisa.ApplyDate}</td>
              </tr>
            ))}
            
            </tbody>
          </table>
        </div>
      </main>
    </>
  )
}

export default AllWorkVisaApplications

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import VisaContextProvider from './context/VisaContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <VisaContextProvider>
    <App />
  </VisaContextProvider>
);
reportWebVitals();




//  Register form validation******/////

export const validateFormRegister = (RegformData) => {
    const errors = {};  
    // name
    if (!RegformData?.name?.trim()) {
      errors.name = 'Name is require *';
    }
  
    //Last name
    if (!RegformData?.lname?.trim()) {
      errors.lname = 'Last Name is require *';
    }
  
    //  email
    if (!RegformData?.email?.trim()) {
      errors.email = 'Email is require *';
    } else if (!isValidEmail(RegformData.email)) {
      errors.email = 'Invalid email address *';
    }
  
    //  password
    if (!RegformData.password || !validatePassword(RegformData.password)) {
      errors.password = 'Fill minimum 6 characters  *';
    }   
    return errors;
  };
 // Validate Email 💯
 const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};



export const validatePassword = (password) => {
    const hasAlphanumeric = /[a-zA-Z0-9]/.test(password);
    return password.length >= 6 && hasAlphanumeric; 
  };

  //  Login form validation******/////

  export const validateFormLogin = (loginData) => {
    const errors = {};
  
    // Validate email
    if (!loginData?.email?.trim()) {
      errors.email = 'Email is required *';
    } else if (!isValidEmail(loginData.email)) {
      errors.email = 'Invalid email address *';
    }
    // Validate password
    if (!loginData.password || !validatePassword(loginData.password)) {
      errors.password = 'Fill minimum 6 characters *';
    }
    return errors;
  };


export const validatePhoneNumbers = (phone) => {
    const phonePattern = /^\d{10}$/; // Simple example: 10-digit numeric phone number
    return phonePattern.test(phone);
  };
  
  export const validatePhoneNumber = (phone) => {
    const phonePattern = /^[0-9]+$/; // Regular expression to allow only numbers  
    return phonePattern.test(phone);
  };
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { FaAmazonPay } from "react-icons/fa6";
import MyLoader from "../../../Loader/MyLoader";
import VisaContext from "../../../../context/visa-context";

const WorkVisaDetails = () => {
  const ctxApi = useContext(VisaContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.userId;
  const [selectedUserData, setSelectedUserData] = useState([]);

  useEffect(() => {
    const fetchWorkData = async () => {
      ctxApi.setLoading(true);
      try {
        const response = await fetch(
          `https://backend-visa2.vercel.app/api/applicant/workVisaData?userId=${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        ctxApi.setLoading(false);
        console.log(data.data); // Process the response data
        const reverseData = data.data.reverse();
        setSelectedUserData(reverseData);
      } catch (error) {
        ctxApi.setLoading(false);
        console.error("Error fetching work data:", error);
      }
    };

    fetchWorkData();
  }, []);

  const handleSendDocument = (application) => {
    navigate("/workvisaDocuments", { state: { application } });
  };

  const handleViewApplications = (id) => {
    navigate("/worvisaApplication", { state: { id } });
  };

  return (
    <>
      <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Work Visa Informations
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Contact No.</th>
                <th>DOB</th>
                <th>Passport No.</th>
                <th>Destination</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {selectedUserData
                .flatMap((datas) => datas.data)
                .map((person, ind) => (
                  <tr key={ind}>
                    <td>{person.name}</td>
                    <td>{person.lname}</td>
                    <td>{person.email}</td>
                    <td>{person.MobileNO}</td>
                    <td>{person.dob}</td>
                    <td>{person.passportNo}</td>
                    <td>{person.destination}</td>
                    <td>{person.ApplyDate}</td>
                    <td>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSendDocument(person)}
                      >
                        <IoEyeSharp
                          style={{ color: "white", fontSize: "21px" }}
                          className="eye_iconHover"
                        />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleViewApplications(person.id)}
                      >
                        <FaAmazonPay
                          style={{ color: "white", fontSize: "21px" }}
                          className="eye_iconHover"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default WorkVisaDetails;

import React, { useContext} from 'react'
import VisaContext from "../../../context/visa-context";
import MyLoader from "../../Loader/MyLoader";

const Freelancers = () => {
    const ctxApi = useContext(VisaContext);  
  return (
    <>
      <main className="main-container">
      {ctxApi.isLoading && <MyLoader />}
      <div className="main-title">
        <h3 style={{ color: "#13e313", fontSize: "25px" }}>Our Freelancer Team</h3>
      </div>      
      <div className="userpage">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile No.</th>
              <th>City</th>
            </tr>
          </thead>
          <tbody>
            {ctxApi.freeData.map((user) => (
              <tr key={user._id}>
               
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.Cnomber}</td>
                <td>{user.City}</td>
              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
    </>
  )
}

export default Freelancers

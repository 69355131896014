import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VisaContext from "../../../../context/visa-context";
import MyLoader from "../../../Loader/MyLoader";

const WorkVisaApplication = () => {
  const ctxApi = useContext(VisaContext)
  const location = useLocation();
  const id = location.state?.id;
  const [selectedUserData, setSelectedUserData] = useState([]);
  //   const applications = ctxApi.customers;
  console.log("selectedUserData", selectedUserData);
  console.log("id", id);

  useEffect(() => {
    // Only fetch data if id is available
    if (id) {
      const fetchWorkApplication = async () => {
        ctxApi.setLoading(true);
        try {
          const response = await fetch(
            `https://backend-visa2.vercel.app/api/applicant/workVisaApplications?id=${id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          ctxApi.setLoading(false);
          console.log(data); // Process the response data
          setSelectedUserData(data.data);
        } catch (error) {
          ctxApi.setLoading(false);
          console.error("Error fetching work data:", error);
        }
      };
      fetchWorkApplication();
    }
  }, [id]); // Add id as a dependency

  return (
    <>
      <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Work Visa Applications
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Name</th>
                <th>Destination</th>
                <th>Price</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{selectedUserData && selectedUserData.name}</td>
                <td>{selectedUserData &&  selectedUserData.lname}</td>
                <td>{selectedUserData &&  selectedUserData.countryName}</td>
                <td>{selectedUserData &&  selectedUserData.countryPrice}</td>
                <td>{selectedUserData &&  selectedUserData.ApplyDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default WorkVisaApplication;

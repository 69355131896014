import React, { useContext } from 'react'
import MyLoader from '../../Loader/MyLoader';
import { useNavigate } from 'react-router-dom';
import VisaContext from '../../../context/visa-context';

const AddOnsData = () => {
    const ctxApi = useContext(VisaContext);
  const navigate = useNavigate();
  const AddOnsDataDisplay = ctxApi.addOnsData;
  return (
    <>
       <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Add Ons Data
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Client</th>
                <th>Booking</th>
                <th>name</th>
                <th>Passport No.</th>
                <th>Price</th>
                <th>From</th>
                <th>To</th>
                <th>OneWay</th>
                <th>TwoWay</th>
                <th>Departure</th>
                <th>Return</th>
              </tr>
            </thead>
            <tbody>
              {AddOnsDataDisplay.map((datas, ind) => {
                return (
                  <tr key={ind}>
                    <td>{datas.client}</td>
                    <td>{datas.AoType}</td>
                    <td>{datas.name}</td>
                    <td>{datas.passportNo}</td>
                    <td>{datas.price}</td>
                    <td>{datas.from}</td>
                    <td>{datas.to}</td>
                   <td>{datas.oneway === true ?( <>
                        <span>Yes</span>
                    </>): ""}</td>
                   <td>{datas.twoway === true ?( <>
                        <span>Yes</span>
                    </>): ""}</td>
                    <td>{datas.dDate}</td>
                    <td>{datas.rDate}</td>                    
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
    </>
  )
}

export default AddOnsData

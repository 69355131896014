
import React, { useState } from "react";
import "./login.css";
import { validateFormLogin } from "../../../utility/validation.js";

const LoginPage = ({ onLogin }) => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    const newErrors = validateFormLogin(loginData);
    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;

    if (isValid) {
      console.log("logForm validation success");
      try {
        const response = await fetch("https://backend-visa2.vercel.app/adminApi/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        });

        const data = await response.json();
        if (response.ok) {
          setMessage(data.message);
          console.log("Login successful");
          localStorage.setItem("admin-token", data.token);
          onLogin();
          // Redirect or update state to reflect login status
        } else {
          setMessage(data.message);
          console.error("Failed to login:", data.message);
        }
      } catch (error) {
        console.error("Error during login:", error);
        setMessage("An error occurred during login. Please try again.");
      }
    } else {
      console.log("loginForm validation failed");
    }
  };

  return (
    <div className="login_wrap">
      <div className="container">
        <div className="">      
          <div className=" left_card">
            <div className="googleLogin">
              <div className="loginHead">
                <h2>Login</h2>
              </div>
              <p>Welcome Back! Please Login To Your Account.</p>
              <form onSubmit={handleSubmitLogin}>
                <div className="input_group mb-4">
                  <input
                    onChange={handleChange}
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={loginData.email}
                  />
                  {errors.email && <p className="style01">{errors.email}</p>}
                </div>
                <div className="input_group mb-4">
                  <input
                    onChange={handleChange}
                    type="password"
                    name="password"
                    value={loginData.password}
                    placeholder="Password"
                  />
                  {errors.password && <p className="style01">{errors.password}</p>}
                </div>
                {message && <p className="invalidEmail">{message}</p>}
                <button className="btn" type="submit">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;



import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import "../UserPage/user.css";
import VisaContext from "../../../context/visa-context";
import MyLoader from "../../Loader/MyLoader";

const UserApplicatons = () => {
  const ctxApi = useContext(VisaContext);
  // const [applications, setApplications] = useState([]);
  const location = useLocation();
  const userId = location.state?.userId || null;
  const applications = ctxApi.applicatons;

  // Filter applications based on userId and get the count
  const userApplications = applications.filter(
    (app) => app.postData.userId === userId
  );
  return (
    <>
      <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Final Informations
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Name</th>
                <th>Destination</th>
                <th>Price</th>
                <th>Selected Date</th>
                <th>Response Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {userApplications.map((datas, ind) => (
                <tr key={ind}>
                  <td>{datas.postData.name}</td>
                  <td>{datas.postData.lname}</td>
                  <td>{datas.postData.countryName}</td>
                  <td>{datas.postData.countryPrice}</td>
                  <td>{datas.postData.selectedDate}</td>
                  <td>{datas.postData.futureDate}</td>
                  <td>{datas.postData.Time}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default UserApplicatons;

import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import "../user.css";
import VisaContext from "../../../../context/visa-context";
import MyLoader from "../../../Loader/MyLoader";

const Application = () => {
  const ctxApi = useContext(VisaContext); 

  return (
    <>
      <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Final Informations
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Name</th>
                <th>Destination</th>
                <th>Price</th>
                <th>Selected Date</th>
                <th>Response Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {ctxApi.applicatons.map((datas, ind) => (
                <tr
                  key={ind}
                >
                  <td>
                    {datas.postData.name}                 
                  </td>
                  <td>{datas.postData.lname}</td>
                  <td>{datas.postData.countryName}</td>
                  <td>{datas.postData.countryPrice}</td>
                  <td>{datas.postData.selectedDate}</td>
                  <td>{datas.postData.futureDate}</td>
                  <td>
                  {datas.postData.Time}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default Application;

import { createContext } from "react";

const VisaContext = createContext({
  isLoading: false,
  setLoading: () => {},
  fetchUsers: () => {},
  users: [],
  fetchApplications: ()=>{},
  applicatons:[],
  fetchAllCustomers:() => {},
  customers:[],
  setApplications: () => {}, // Add the setApplications method
  freeData:[],
  workApplications:[],
  addOnsData:[]
});

export default VisaContext;


import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MdDownloadForOffline } from "react-icons/md";

const WorkVisaDocuments = () => {
    const location = useLocation();
    const application = location.state?.application;
    const [photos, setPhotos] = useState([]);
    const [passport, setPassport] = useState([]);
    const [otherDoc, setOtherDoc] = useState([]);

    console.log("application",application)
  
    useEffect(() => {
      if (application) {
        setPhotos(application.photos || []);
        setPassport(application.passport || []);
        setOtherDoc(application.otherDoc || []);
      }
    }, [application]);
  
    const downloadFile = async (filename) => {
      try {
        const response = await fetch(`https://backend-visa2.vercel.app/api/applicant/download-files?filename=${filename}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
    
        // Check content-type to ensure it's JSON
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          const text = await response.text();
          throw new Error(`Expected JSON, but got: ${text}`);
        }
    
        const data = await response.json();
        const downloadURL = data.downloadURL;  
        const link = document.createElement("a");
        link.href = downloadURL;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    };
  return (
    <>
       <main className="main-container">
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>User Document</h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Passport</th>
                <th>Other Documents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {photos.map((item, ind) => (
                    <div
                      key={ind}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="document-fileName"
                    >
                      <p>{item.filename}</p>{" "}
                      <span>
                        <MdDownloadForOffline
                          style={{
                            marginTop: "8px",
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                          className="downloadIcons"
                          onClick={() => downloadFile(item.originalname)}
                        />
                      </span>
                    </div>
                  ))}
                </td>
                <td>
                  {passport.map((item, ind) => (
                    <div
                     className="document-fileName"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={ind}
                    >
                      <p >{item.filename}</p>{" "}
                      <span>
                        <MdDownloadForOffline
                          style={{
                            marginTop: "8px",
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                          className="downloadIcons"
                          onClick={() => downloadFile(item.originalname)}
                        />
                      </span>
                    </div>
                  ))}
                </td>
                <td>
                  {otherDoc.map((item, ind) => (
                    <div
                     className="document-fileName"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={ind}
                    >
                      <p >{item.filename}</p>{" "}
                      <span>
                        <MdDownloadForOffline
                          style={{
                            marginTop: "8px",
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                          className="downloadIcons"
                          onClick={() => downloadFile(item.originalname)}
                        />
                      </span>
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </>
  )
}

export default WorkVisaDocuments
